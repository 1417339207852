.u-btn {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;

  &::before {
    content: '';
    display: inline-block;
    border-width: .7em 0 .7em 1em;
    border-style: solid;
    border-color: transparent transparent transparent var(--black);
  }
}
