.header-clone {
  display: none;
}
.site-header {
  @media (--desktop) {
    background-color: transparent;
    position: fixed;
  }
  @media (--phone) {
    padding: 5px 0;
  }
}
