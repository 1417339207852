.mainnav {
  .menu-item {
    text-transform: capitalize;
  }

  &#mainnav {
    .menu {
      .menu-item {
        padding: 0 2.4em;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        > a {
          display: inline-block;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: -.5em;
            height: 2px;
            background-color: var(--red);
            visibility: hidden;
            transform: translateX(-1em);
          }
        }

        &.current_page_item,
        &.current_menu_item {
          > a::after {
            visibility: visible;
            transform: translateX(0);
          }
        }

        &:hover > a::after {
          visibility: visible;
          transform: translateX(0);
          transition: .4s;
        }
      }
    }
  }
}
