.c-flex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  > .wpb_column > .vc_column-inner,
  > .wpb_column > .vc_column-inner > .wpb_wrapper,
  > .wpb_column > .vc_column-inner > .wpb_wrapper > .wpb_content_element {
    height: 100%;
  }
}
