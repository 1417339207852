body,
html {
  position: relative;
  margin-top: 0 !important;
  padding: 0 !important;
  overflow-x: hidden;
  line-height: 1.4;
  font-size: responsive 14px 16px;
  font-range: 420px 1280px;
}

h1,h2,h3,
h4, h5, h6,
p, ul, 
address {
  margin: 0;
}

ul {
  padding-left: 0;
  list-style: none;
}

.container {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (max-width: 991px){
  .header-wrap .col-md-8 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.site-main .entry-header {
  display: none;
}

.page .page-wrap .content-wrapper,
.single .page-wrap .content-wrapper {
  padding: 0;
}

.wpb_button, .wpb_content_element, ul.wpb_thumbnails-fluid > li {
  margin-bottom: 0;
}
